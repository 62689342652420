import React, { Fragment } from "react";
import SlideShow from "../components/Slideshow";
import Welcome from "../components/Welcome";
import About from "../components/About";
import Experience from "../components/Experience";
import Performance from "../components/Performance";
import Card from "../components/Card";

export default () => {
  return (
    <Fragment>
      <Card />
      <SlideShow />
      <Welcome />
      <Experience />
      <About />
      <Performance />
    </Fragment>
  );
};
