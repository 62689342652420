import React from "react";

export default () => (
  <div className="uk-section-primary uk-section" data-uk-scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-slide-left-medium; delay: false;">
    <div className="uk-width-1-1">
      <div className="uk-padding uk-padding-remove-vertical">
        <h3 className="uk-heading-line uk-text-center">
          <span>Erfahrung und Kompetenz</span>
        </h3>
        <div className="uk-grid-margin uk-grid" data-uk-grid="">
          <div className="uk-width-expand@s uk-first-column uk-flex uk-flex-center">
            <img alt="Immobilien Hermann Behringer" data-src="/assets/25-years-white.svg" data-uk-img="" />
          </div>
          <div className="uk-width-2-3@s uk-grid-item-match uk-flex-middle">
            <div className="uk-panel">
              <div className="uk-margin" data-uk-scrollspy-class="uk-animation-slide-right-medium">
                Seit 1994 wurden über 1000 Verträge erfolgreich vermittelt und seit 2009 über 400 Immobilien bewertet.
              </div>
              <div className="uk-child-width-1-4@s uk-flex uk-flex-right" data-uk-grid="" data-uk-lightbox="animation: fade">
                <div>
                  <a className="uk-inline" href="/assets/zertifikat.jpeg" data-caption="Caption 2">
                    <img src="/assets/zertifikat.jpeg" alt="Zertifikat" />
                  </a>
                </div>
                <div>
                  <a className="uk-inline" href="/assets/urkunde.jpeg" data-caption="Caption 1">
                    <img src="/assets/urkunde.jpeg" alt="Urkunde" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
);
