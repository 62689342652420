import React, { Fragment } from "react";

export default () => (
  <Fragment>
    <div className="uk-section uk-section-muted">
      <div className="uk-padding uk-padding-remove-vertical">
        <div className="uk-background-contain uk-background-norepeat uk-panel uk-flex uk-flex-center uk-flex-middle title-background">
          <h3 className="uk-background-muted uk-padding-small">Kontakt</h3>
        </div>
        <div className="uk-grid-margin uk-grid uk-grid-match" data-uk-grid="">
          <div className="uk-width-expand@m uk-first-column uk-flex uk-flex-center uk-flex-middle">
            <div className="uk-card uk-card-default uk-card-body uk-text-center">
              <ul className="uk-list uk-text-lead">
                <li>
                  <span>
                    <a className="uk-grid-collapse uk-flex-middle uk-link-reset" href="tel:08966063640">
                      089-66063640
                    </a>
                    <br />
                    <a className="uk-grid-collapse uk-flex-middle uk-link-reset" href="tel:01638181928">
                      0163-8181928
                    </a>
                  </span>
                </li>
                <li>
                  <a className="uk-link-muted uk-grid-collapse uk-flex-middle uk-link-reset" href="mailto:info@immobilien-ihb.de">
                    info@immobilien-ihb.de
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="uk-width-auto@m uk-flex uk-flex-center uk-flex-middle">
            <img alt="Immobilien Hermann Behringer" data-uk-scrollspy-class="uk-animation-fade" width={200} height={200} src="/assets/behringer-foto.jpg" />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
