import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function CustomLink({ children, to }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className={match ? "uk-active" : ""}>
      <Link to={to}> {children}</Link>
    </li>
  );
}

export default () => (
  <div className="uk-hidden@m uk-padding uk-padding-remove-vertical">
    <nav className="uk-navbar-container uk-navbar" data-uk-navbar="mode: click">
      <div className="uk-navbar-left">
        <Link to="/" className="uk-navbar-item uk-padding-remove-left uk-logo">
          <img src="/logo.svg" alt="Immobilien Hermann Behringer" width="80" />
          <span className="uk-visible@s">Immobilien Hermann Behringer</span>
        </Link>
      </div>
      <div className="uk-navbar-right">
        <button data-uk-navbar-toggle-icon="menu" data-ratio="2" className="uk-navbar-item uk-navbar-toggle-icon uk-icon uk-padding-remove-right" />
        <div
          className="uk-navbar-dropdown uk-background-default uk-navbar-dropdown-width-2"
          data-uk-drop="boundary: !nav; boundary-align: true; pos: bottom-justify; offset: 0"
        >
          <div className="uk-navbar-dropdown-grid uk-child-width-1-2 uk-text-center" data-uk-grid="">
            <div className="uk-first-column">
              <ul className="uk-nav uk-navbar-dropdown-nav">
                <li className="uk-nav-header">Kontakt</li>
                <li>
                  <span>
                    <a className="uk-grid-collapse uk-flex-middle uk-link-reset" href="tel:08966063640">
                      089-66063640
                    </a>
                    <br />
                    <a className="uk-grid-collapse uk-flex-middle uk-link-reset" href="tel:01638181928">
                      0163-8181928
                    </a>
                  </span>
                </li>
                <li>
                  <a className="uk-link-muted uk-grid-collapse uk-flex-middle uk-link-reset" href="mailto:info@immobilien-ihb.de">
                    info@immobilein-ihb.de
                  </a>
                </li>
                <li className="uk-nav-header">Geschäftszeiten:</li>
                <li>
                  <span>Mo-Fr 09:00 - 17:00</span>
                </li>
              </ul>
            </div>
            <div>
              <ul className="uk-nav uk-navbar-dropdown-nav">
                <li className="uk-nav-header">Menu</li>
                <CustomLink to="/">Home</CustomLink>
                <CustomLink to="/about">Über uns</CustomLink>
                <CustomLink to="/contact">Kontakt</CustomLink>
                <CustomLink to="/imprint">Impressum</CustomLink>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
);
