import React from "react";
import { Link } from "react-router-dom";

export default () => (
  <div className="uk-section-primary uk-section uk-section-small" data-uk-scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-fade; delay: false;">
    <div className="uk-width-1-1">
      <div className="uk-padding uk-padding-remove-vertical">
        <div className="uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-match uk-grid-margin uk-grid" data-uk-grid="">
          <div className="uk-first-column uk-visible@m uk-flex-middle">
            <div className="uk-flex uk-flex-center">
              <img width="80" height="80" src="/logo-inverse.svg" alt="Immobilien Hermann Behringer" data-uk-scrollspy-class="uk-animation-fade" />
            </div>
          </div>
          <div>
            <div className="uk-panel">
              <>Büro München</>
              <div className="uk-margin">
                Schlörstrasse 8<br />
                80634 München
              </div>
              <div className="uk-margin-remove-vertical">
                <a className="uk-link-reset" href="tel:08966063640">
                  089-66063640
                </a>
              </div>
              <div className="uk-margin-remove-vertical">
                <a className="uk-link-reset" href="tel:01638181928">
                  0163-8181928
                </a>
              </div>
              <div className="uk-margin-remove-vertical">
                <a className="uk-link-reset" href="mailto:info@immobilien-ihb.de">
                  <span>info@immobilien-ihb.de</span>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="uk-panel">
              <div className="uk-child-width-auto uk-flex-column uk-grid" data-uk-grid="">
                <div>
                  <Link className="uk-link-reset" to="/imprint">
                    Impressum
                  </Link>
                </div>
                <div className="uk-margin-remove">
                  <Link className="uk-link-reset" to="/privacy">
                    Datenschutz
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
);
