import React, { Fragment } from "react";
import About from "../components/About";
import Experience from "../components/Experience";
import Performance from "../components/Performance";
import Card from "../components/Card";

export default () => {
  return (
    <Fragment>
      <Card />
      <About />
      <Experience />
      <Performance />
    </Fragment>
  );
};
