import React from "react";
import { Link } from "react-router-dom";

const FourOhFour = () => {
  return (
    <div data-uk-height-viewport="offset-top: true; offset-bottom: true" className="uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-text-center">
      <div className="uk-heading-small uk-margin-remove">404</div>
      <h1 className="uk-margin-remove-top">Seite wurde nicht gefunden</h1>
      <Link to="/" className="uk-button uk-button-primary">
        Zurück zur Startseite
      </Link>
    </div>
  );
};

export default FourOhFour;
