import React, { Fragment } from "react";
import Contact from "../components/Contact";
import Experience from "../components/Experience";

export default () => (
  <Fragment>
    <Experience />
    <Contact />
  </Fragment>
);
