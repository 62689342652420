import React from "react";
import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Imprint from "./components/Imprint";
import Privacy from "./components/Privacy";
import FourOhFour from "./pages/FourOhFour";
import "./App.less";
import Layout from "./components/Layout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<FourOhFour />} />
      </Route>
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
}

export default App;
