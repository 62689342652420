import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function CustomLink({ children, to }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className={match ? "uk-active" : ""}>
      <Link className="uk-button uk-button-text" to={to}>
        {children}
      </Link>
    </li>
  );
}

export default () => {
  return (
    <div className="uk-visible@m" data-uk-header="true">
      <div
        data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top; cls-active: uk-navbar-sticky; sel-target: .uk-navbar-container"
        className="uk-sticky"
      >
        <div className="uk-navbar-container">
          <div className="uk-container uk-container-expand">
            <nav
              className="uk-navbar uk-padding uk-padding-remove-vertical"
              data-uk-navbar="align:left; boundary:!.uk-navbar-container; dropbar:true; dropbar-anchor:!.uk-navbar-container; dropbar-mode:slide"
            >
              <div className="uk-navbar-left uk-padding-remove">
                <Link to="/" className="uk-padding-remove-left uk-navbar-item uk-logo">
                  <img
                    className="uk-padding-small uk-padding-remove-left uk-padding-remove-top"
                    width="80"
                    src="/logo.svg"
                    alt="Immobilien Hermann Behringer"
                  />
                  <span>Immobilien Hermann Behringer</span>
                </Link>
              </div>
              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav">
                  <CustomLink to="/">Home</CustomLink>
                  <CustomLink to="/about">Über uns</CustomLink>
                  <CustomLink to="/contact">Kontakt</CustomLink>
                  <CustomLink to="/imprint">Impressum</CustomLink>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
