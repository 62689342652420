import React, { useState, useEffect } from "react";

const Slideshow = () => {
  let [list, setList] = useState([]);

  useEffect(() => {
    const time = { month: new Date().getMonth(), april: 3, october: 9 };
    const season = time.month > time.april && time.month < time.october ? "sommer" : "winter";

    const currentList = [];

    for (let i = 0; i < (season === "sommer" ? 8 : 11); i++) {
      const key = (i + 1).toString().padStart(2, "0");
      currentList.push({ key, season });
    }

    setList(currentList);
  }, []);

  return (
    <div
      className="uk-section-default uk-padding-remove-vertical uk-section"
      data-uk-scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-slide-left-medium; delay: false;"
    >
      <div className="uk-width-1-1">
        <div
          className="uk-position-relative uk-light uk-visible-toggle uk-slideshow"
          data-uk-slideshow="animation: scale; ratio: 8:3; autoplay: true; autoplay-interval: 6000"
        >
          <button
            className="uk-button-link uk-position-center-left uk-position-small uk-slidenav-previous uk-hidden-hover uk-position-z-index"
            data-uk-slideshow-item="previous"
            data-uk-icon="chevron-left"
            data-ratio="2"
          />
          <button
            className="uk-button-link uk-position-center-right uk-position-small uk-slidenav-next uk-hidden-hover uk-position-z-index"
            data-uk-slideshow-item="next"
            data-uk-icon="chevron-right"
            data-ratio="2"
          />
          <ul className="uk-slideshow-items">
            {list.map((item) => (
              <li key={item.key}>
                <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                  <img src={`images/${item.season}${item.key}.jpg`} alt={`${item.season}-${item.key}`} data-uk-cover="" />
                </div>
              </li>
            ))}
          </ul>
          <div className="uk-position-bottom uk-margin-bottom">
            <ul className="uk-slideshow-nav uk-dotnav uk-dotnav-contrast uk-flex-center uk-margin-bottom-remove" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
