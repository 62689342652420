import React from "react";

export default () => (
  <div className="uk-section-muted uk-section" data-uk-scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-slide-left-medium; delay: false;">
    <div className="uk-width-1-1">
      <div className="uk-padding uk-padding-remove-vertical">
        <h3 className="uk-heading-line uk-text-center">
          <span>Leistungen</span>
        </h3>
        <div className="uk-grid-margin-large uk-child-width-1-3@m uk-grid-match uk-text-center" data-uk-grid="" data-uk-height-match="target: > div > .uk-card">
          <div className="uk-display-block">
            <div className="uk-card uk-card-small uk-card-default uk-width-medium uk-margin-auto">
              <div className="uk-card-media-top">
                <img src="/assets/card-header.svg" alt="" />
              </div>
              <div className="uk-card-body">
                {/*<img*/}
                {/*  alt=""*/}
                {/*  src="https://demo.yootheme.com/themes/joomla/2017/morgan-consulting/images/yootheme/services-02-legal-advisory.svg"*/}
                {/*/>*/}
                <h3 className="uk-card-title">Immobilienbewertung</h3>
                <p>Wertermittlung ihrer Immobilie nach Sachwert Vergleichswert Ertragswertverfahren</p>
                <p>Optimale Positionierung ihrer Immobilien am Markt durch exzellentes Know-how und langjährige Erfahrung</p>
              </div>
            </div>
          </div>
          <div className="uk-display-block">
            <div className="uk-card uk-card-small uk-card-default uk-width-medium uk-margin-auto">
              <div className="uk-card-media-top">
                <img src="/assets/card-header.svg" alt="" />
              </div>
              <div className="uk-card-body">
                {/*<img*/}
                {/*  alt=""*/}
                {/*  src="https://demo.yootheme.com/themes/joomla/2017/morgan-consulting/images/yootheme/services-03-financial-services.svg"*/}
                {/*/>*/}
                <h3 className="uk-card-title">Immobilienverkauf</h3>
                <p>
                  Diskrete Abwicklung und persönliche Betreuung in allen Verkaufsphasen von der Marktpreisermittlung bis zum Notartermin …und darüber hinaus!
                </p>
              </div>
            </div>
          </div>
          <div className="uk-display-block">
            <div className="uk-card uk-card-small uk-card-default uk-width-medium uk-margin-auto">
              <div className="uk-card-media-top">
                <img src="/assets/card-header.svg" alt="" />
              </div>
              <div className="uk-card-body">
                {/*<img*/}
                {/*  alt=""*/}
                {/*  src="https://demo.yootheme.com/themes/joomla/2017/morgan-consulting/images/yootheme/services-05-audit-and-assurance.svg"*/}
                {/*/>*/}
                <h3 className="uk-card-title">Immobilienempfehlung</h3>
                <p>
                  Unser Geschäft ist ein Empfehlungsgeschäft. Wir arbeiten vorwiegend auf Empfehlungsbasis. Auch Sie haben die Möglichkeit davon zu profitieren,
                  bitte fragen Sie nach unserem Empfehlungsprogramm.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
