import React from "react";

export default () => (
  <div className="uk-section-default uk-section" data-uk-scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-slide-left-medium; delay: false;">
    <div className="uk-width-1-1">
      <div className="uk-padding uk-padding-remove-vertical">
        <div className="uk-background-contain uk-background-norepeat uk-panel uk-flex uk-flex-center uk-flex-middle title-background">
          <h3 className="uk-background-default uk-padding-small">Willkommen</h3>
        </div>
        <div className="uk-grid-margin uk-grid" data-uk-grid="">
          <div className="uk-width-2-3@m uk-grid-item-match uk-flex-middle uk-first-column">
            <div className="uk-panel">
              <div className="uk-margin uk-scrollspy-inview " data-uk-scrollspy-class="uk-animation-slide-left-medium">
                <p>Grüß Gott und Herzlich Willkommen bei Immobilien Hermann Behringer, Ihr erfahrener Partner für Immobilien in München und im Allgäu.</p>

                <p>
                  Als traditionsreicher Immobilienmakler stehe ich Käufern und Verkäufern zu allen Fragen rund um Immobilien kompetent zur Verfügung. Die
                  Kerngebiete unseres Immobilienangebotes erstrecken sich über die Metropolregion München sowie den Großraum Allgäu. Durch die langjährige
                  Tätigkeit als Immobilienmakler und Sachverständiger für die Bewertung von bebauten und unbebauten Grundstücken in diesen Gebieten kenne ich
                  die Bedürfnisse von Wohnungssuchenden ebenso gut wie die Interessen von Anlegern. Mein Ziel ist es, stets für beide Seiten ein
                  zufriedenstellendes Ergebnis zu erreichen.
                </p>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m uk-flex uk-flex-center">
            <img
              alt="Immobilien Hermann Behringer"
              data-uk-scrollspy-class="uk-animation-slide-right-medium"
              data-src="/logo.svg"
              data-uk-img=""
              src="/logo.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
