import React from "react";

export default () => (
  <div className="uk-position-fixed uk-position-center-left uk-position-z-index uk-visible@l">
    <div className="uk-card uk-card-small uk-card-default uk-box-shadow-medium uk-width-medium">
      <div className="uk-card-media-top">
        <img src="/assets/card-header.svg" alt="" />
      </div>
      <div className="uk-card-body">
        <h3 className="uk-card-title">Kontakt</h3>
        <h4 className="uk-h5 uk-margin">
          Immobilien
          <br /> Hermann Behringer
        </h4>
        <ul className="uk-list">
          <li>
            <span>
              <a className="uk-grid-collapse uk-flex-middle uk-link-reset" href="tel:08966063640">
                089-66063640
              </a>
              <br />
              <a className="uk-grid-collapse uk-flex-middle uk-link-reset" href="tel:01638181928">
                0163-8181928
              </a>
            </span>
          </li>
          <li>
            <a className="uk-link-muted uk-grid-collapse uk-flex-middle uk-link-reset" href="mailto:info@immobilien-ihb.de">
              info@immobilien-ihb.de
            </a>
          </li>
          <li>
            <a href="https://www.hausundgrundkempten.de" target="_blank" rel="noopener noreferrer">
              <img data-src="/assets/hug-logo.svg" alt="Haus ung Grund Logo" data-uk-img="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);
