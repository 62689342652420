import React from "react";

export default () => (
  <div className="uk-section-default uk-section" data-uk-scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-slide-left-medium; delay: false;">
    <div className="uk-width-1-1">
      <div className="uk-padding uk-padding-remove-vertical">
        <div className="uk-background-contain uk-background-norepeat uk-panel uk-flex uk-flex-center uk-flex-middle title-background">
          <h3 className="uk-background-default uk-padding-small">Über uns</h3>
        </div>
        <div className="uk-grid-margin uk-grid" data-uk-grid="">
          <div className="uk-width-expand@m uk-first-column">
            <div className="uk-panel">
              <div className="uk-margin uk-scrollspy-inview" data-uk-scrollspy-class="uk-animation-slide-left-medium">
                <div>Hermann Behringer</div>
                <div>Diplom-Betriebswirt (FH)</div>
                <div>Sachverständiger für die Bewertung von bebauten und unbebauten Grundstücken</div>
                <div>geprüft durch die Deutsche-Sachverständigen-Gesellschaft mbH DESAG</div>
                <div>Seit über 25 Jahren erfolgreich in der Immobilienwirtschaft tätig</div>
              </div>
            </div>
          </div>
          <div className="uk-width-auto@m uk-text-center">
            <img alt="Immobilien Hermann Behringer" data-uk-scrollspy-class="uk-animation-fade" width={200} height={200} src="/assets/behringer-foto.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
);
