import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Mobile from "./Mobile";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";

export default () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <div className="uk-container uk-container-small uk-padding-remove uk-box-shadow-large">
      <Mobile />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};
